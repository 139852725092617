import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController, MenuController } from '@ionic/angular';
import { Router } from '@angular/router';
import { Subscription, race } from 'rxjs';
import { selectIsUserLoggedIn } from '@store/auth/auth.selectors';
import { Store } from '@ngrx/store';
import { AppState } from '@store/app.reducer';
import { TickethistoryService } from 'src/app/pages/betslip/tickethistory/tickethistory.service';
import {
  tickethistoryActions,
  authActions,
  buyBackActions,
  ticketActions
} from '@store/actions';
import { TranslateService } from '@ngx-translate/core';
import { PresentMessageService } from 'src/app/services/present-message.service';

import { OddsModalComponent } from 'src/app/pages/betslip/tickethistory/odds-modal/odds-modal.component';
import { TicketHistroyDetail } from '@models/TicketHistory';
import { getOddsCounts } from '@core/functions/parseOddsStats/parseOddsStats';
import { BuybackService } from '../buyback/buyback.service';
import { XxlBonusComponent } from 'src/app/pages/ticket/xxl-bonus/xxl-bonus.component';
import { TranslateSpecialPipe } from '@shared/pipes/translate.pipe';
import { translateRoute } from '@core/functions/translateRoute';
import { Actions, ofType } from '@ngrx/effects';
import { first, tap } from 'rxjs/operators';
import { ticketSelectors } from '@store/selectors';
import { trycatch } from '@core/tryCatch/tryCatch';
import { configuration } from '@configuration';

@Component({
  selector: 'doxx-ticketdetail',
  templateUrl: './ticketdetail.component.html',
  styleUrls: ['./ticketdetail.component.scss']
})
export class TicketdetailComponent implements OnInit, OnDestroy {
  isClientLoggedIn: boolean;
  private _subscription$: Subscription;
  _ticketDetail: TicketHistroyDetail;
  _buyback: boolean;
  _hasActiveOdds: boolean;
  configuration = configuration;
  _oddsStats = {
    Y: {
      count: 0,
      percent: 0
    },
    U: {
      count: 0,
      percent: 0
    },
    N: {
      count: 0,
      percent: 0
    }
  };

  @Input() isStoredTicket = false;
  @Input() set ticketDetail(ticketDetail: TicketHistroyDetail) {
    this._ticketDetail = ticketDetail;
    this._hasActiveOdds = ticketDetail.odds?.some(
      odd => odd.eventStatus === 'active'
    );
    this._oddsStats = getOddsCounts(ticketDetail.oddsStats || '');
  }

  @Input() set buyback(buyBack: boolean) {
    this._buyback = buyBack;
  }
  constructor(
    private _modalController: ModalController,
    private _store: Store<AppState>,
    private _ticketHistoryService: TickethistoryService,
    private _menu: MenuController,
    private _router: Router,
    private _translateService: TranslateService,
    private _translateSpecial: TranslateSpecialPipe,
    private _presentMessageService: PresentMessageService,
    private _buybackService: BuybackService,
    private _actions$: Actions
  ) {}

  xxlBonus = new XxlBonusComponent(
    this._presentMessageService,
    this._translateSpecial,
    this._translateService
  );
  /**
   * nacita buyback tesne po nacitani
   */
  ngOnInit(): void {
    this._subscription$ = new Subscription();

    this._subscription$.add(
      this._store
        .select(selectIsUserLoggedIn)
        .subscribe((isLoggedIn: boolean) => {
          this.isClientLoggedIn = isLoggedIn;
        })
    );
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this._subscription$.unsubscribe();
  }

  /**
   * Duplicate current opened ticket
   */
  duplicateTicket(ticket: TicketHistroyDetail): void {
    race(
      this._actions$.pipe(
        ofType(ticketActions.cloneTicketSuccess),
        tap(() => {
          this._router.navigate([translateRoute('Landing')]);
          this._menu.open('ticketMenu');
        })
      ),
      this._actions$.pipe(ofType(ticketActions.cloneTicketFailure))
    )
      .pipe(first())
      .subscribe();
    this._store.dispatch(
      ticketActions.cloneTicket({ ticketCode: ticket.ticketCode })
    );
  }

  /**
   * Cancel current opened ticket
   */
  cancelTicket(ticket): void {
    this._ticketHistoryService.stornoTicket(ticket.ticketCode).subscribe(
      data => {
        if (data.data.action.status === 'ok') {
          this._ticketDetail.state = 'canceled';
          this.reloadTicket();
          this._store.dispatch(
            buyBackActions.removeTicket({ ticketCode: ticket.ticketCode })
          );
          this._store.dispatch(authActions.balanceRefreshStart());
          this._presentMessageService.presentCustomMessage(
            this._translateService
              .instant('ticket_actions_cancel_msg2')
              .replace('{0}', '#' + ticket.ticketCode),
            'success-alert'
          );
        } else {
          this._presentMessageService.presentCustomMessage(
            data.errors.validation[0].messages[0],
            'error-alert'
          );
        }
      },
      () => {},
      () => {
        this.reloadTicket();
      }
    );
  }

  /** start editing ticket, close history and show ticket */
  editTicket(ticket: TicketHistroyDetail): void {
    this._actions$
      .pipe(ofType(ticketActions.editTicketComplete), first())
      .subscribe(({ ticket: _ticket }) => {
        if (_ticket?.IsEdited && _ticket?.Errors?.length === 0) {
          if (ticket.virtualActivity) {
            this._router.navigate([translateRoute('VirtualGames')]);
          } else {
            this._router.navigate([translateRoute('PrematchList')], {
              queryParams: { date: 'TD' }
            });
          }
          this._menu.open('ticketMenu');
        } else {
          const errorCode = trycatch(
            () => _ticket.Errors[0].split(/;|:/)[0],
            null
          );
          this._presentMessageService.presentCustomMessage(
            this._translateService.instant(
              errorCode
                ? this._translateService.translations[
                    this._translateService.currentLang
                  ]
                  ? this._translateService.instant(
                      `errors_ticket_error_${errorCode}`
                    )
                  : this._translateService.instant(`newticket_${errorCode}`)
                : 'newticket_SYSTEM_ERROR'
            ),
            'info-alert',
            '',
            ['OK']
          );
        }
      });

    this._store.dispatch(
      ticketActions.editTicket({ ticketCode: ticket.ticketCode })
    );
  }

  /** show ticket chart */
  showTicketChart(): void {
    this._modalController
      .create({
        component: OddsModalComponent,
        cssClass: 'odds-modal',
        componentProps: {
          oddStats: this._ticketDetail.oddsStats
        }
      })
      .then(modal => {
        modal.present();
      });
  }

  /** reloadTicket */
  reloadTicket(): void {
    this._store.dispatch(
      tickethistoryActions.loadTicketDetail({
        ticketCode: this._ticketDetail.ticketCode
      })
    );
  }

  /** show buyback modal */
  showBuybackModal(ticketCode) {
    this._buybackService.showBuybackModal(ticketCode).then(executed => {
      if (executed) {
        this.reloadTicket();
      }
    });
  }
}
