import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER,
  Injectable,
  InjectionToken,
  Injector,
  NgModule
} from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { maintenanceActions } from '@store/actions';
import { catchError, map, retry } from 'rxjs/operators';
import { initNavigation } from '../../navigation';
import { JavaBackendInterceptor } from './interceptors/JavaBackendInterceptor';
import { LoginCheckInterceptor } from './interceptors/LoginCheckInterceptor';
import { MaintenanceService } from './services/maintenance/maintenance.service';
import * as fromApp from '../store/app.reducer';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { MaintenanceEffects } from '@store/maintenance/maintenance.effects';
import { AuthEffects } from '@store/auth/auth.effects';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { oneLine } from './functions/oneLine';
import { environment } from '@environment';
import {
  ActivatedRouteSnapshot,
  RouteReuseStrategy,
  RouterModule
} from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieService } from 'ngx-cookie-service';
import { SeoModule } from '@core/seo';
import {
  IsRoutingInitializedGuard,
  IS_APPLICATION_INITIALIZED
} from './guards/is-routing-initialized.guard';
import { DOCUMENT, Location } from '@angular/common';
import { MainService } from './services/main/main.service';
import { TranslationService } from './services/translation/translation.service';
import { configuration, transformConfig } from '@configuration';
import { TimezoneService } from './services/timezone/timezone.service';
import { CaptchaModule } from '@core/captcha/captcha.module';
import { EmbededOfferService } from './services/embeded-offer/embeded-offer.service';
import SwiperCore, { Pagination, Autoplay } from 'swiper';
import { BuybackService } from '@shared/components/buyback/buyback.service';
import { NotificationsModule } from '@shared/components/notifications/notifications.module';
import {
  generateDeviceCode,
  isValidDeviceCode
} from './functions/generateDeviceCode/generateDeviceCode';

SwiperCore.use([Pagination, Autoplay]);

export const DEVICE_CODE = new InjectionToken<{ getCode(): string }>('');

const resolveUsedLang = (
  cookieService: CookieService,
  location: Location,
  translationService: TranslationService
) => {
  try {
    // priorita jazykov
    // 1. validny jazyk z cookies
    // 2. validny jazyk zo settingov browseru
    // 3. jazyk pouzity v URL
    // 4. defaultny jazyk z environments

    const agent = window.navigator.userAgent;

    const botUserAgentsArray = [
      'googlebot',
      'bingbot',
      'linkedinbot',
      'mediapartners-google',
      'lighthouse',
      'insights'
    ];

    let isBotUserAgent = 0;
    for (const bot of botUserAgentsArray) {
      if (agent.toLowerCase().indexOf(bot.toLowerCase()) !== -1) {
        console.log('botUserAgent found: ' + bot.toLowerCase());
        isBotUserAgent = 1;
        break;
      }
    }

    if (isBotUserAgent === 1) {
      //Do what you have to do
      environment.language = configuration.base.languages.find(
        lang => lang.code === location.path().split('/')[1]
      )?.code;
    } else {
      environment.language =
        configuration.base.languages.find(
          lang => lang.code === cookieService.get(environment.cookies.lang.key)
        )?.code ||
        configuration.base.languages.find(
          lang => lang.code === location.path().split('/')[1]
        )?.code ||
        configuration.base.languages.find(
          lang => lang.code === navigator.language.split('-')[0]
        )?.code ||
        configuration.base.defaultLanguage;
    }
  } catch (ignored) {
    environment.language = configuration.base.defaultLanguage;
  } finally {
    translationService.setTranslation(environment.language, false);
  }
};

const resolveUsedTimezone = (timezoneService: TimezoneService) => {
  timezoneService.timezone = configuration.base.defaultTimezone;
};
@Injectable()
export class RouteConfigLoader {
  constructor(private _mainService: MainService) {}
  /** Nacita route config z API */
  loadRoutes(): Promise<any> {
    return this._mainService
      .fetchRouterConfig()
      .toPromise()
      .then(Result => initNavigation(Result))
      .catch(e => {
        throw new Error('Router config not load: ' + e.message);
      });
  }
}

@Injectable()
export class ConfigLoader {
  constructor(private _mainService: MainService) {}
  /** load and init config */
  loadConfig(): Promise<any> {
    return this._mainService.getConfig().toPromise();
  }
}

@Injectable()
export class MaintenanceLoader {
  constructor(private _maintenanceService: MaintenanceService) {}
  /** Load initial state for maintenance */
  loadMaintenance(): Promise<any> {
    return this._maintenanceService.getBlockingInformation().toPromise();
  }
}

const bootsrap = (injector: Injector) => () => {
  return (
    new Promise<any>((resolve, reject) => {
      injector
        .get(ConfigLoader)
        .loadConfig()
        .then(async config => {
          const transformed = transformConfig(config);
          for (const key in transformed) {
            if (Object.prototype.hasOwnProperty.call(transformed, key)) {
              const element = transformed[key];
              delete configuration[key];
              configuration[key] = element;
              delete transformed[key];
            }
          }
          try {
            resolveUsedLang(
              injector.get(CookieService),
              injector.get(Location),
              injector.get(TranslationService)
            );
            injector
              .get(DOCUMENT)
              ?.head?.parentElement?.setAttribute('lang', environment.language);
            resolveUsedTimezone(injector.get(TimezoneService));
            const [routes, maintenance] = await Promise.all([
              injector.get(RouteConfigLoader).loadRoutes(),
              injector.get(MaintenanceLoader).loadMaintenance()
            ]);
            resolve(maintenance);
          } catch (error) {
            reject(error);
          }
        })
        .catch(error => {
          injector
            .get(TranslationService)
            .setTranslation(environment.language, false);
          reject(error);
        });
    })
      .then(maintenance => {
        const store = injector.get(Store);
        store.dispatch(maintenanceActions.initMaintenance({ maintenance }));

        (window as any).__config = configuration;
      })
      // on error block app and show maintenance
      .catch(error => {
        const store = injector.get(Store);
        store.dispatch(maintenanceActions.blockApp());
        (window as any).__configError = error;
      })
      .finally(() => {
        const store = injector.get(Store);
        store.dispatch({ type: 'APP_INITIALIZED' });
        injector.get(IS_APPLICATION_INITIALIZED).next(true);
      })
  );
};

/**
 * Load labels from backend
 * Language id will be automaticaly bind to url see: https://github.com/ngx-translate/core#aot
 */
@Injectable()
class CustomTranslateLoader implements TranslateLoader {
  constructor(private _http: HttpClient) {}
  /**  Loads and transforms labels after fetch */
  getTranslation(lang: string): Observable<any> {
    //TODO: vyriesit na zaklade akeho param tahat preklady
    if (environment.location === 'nga') {
      return this._http
        .get<any>(
          oneLine`${environment.apiUrl}/responsive/web/app/portalclient/labels`
        )
        .pipe(
          map(result => {
            if (Object.keys(result.Result).length === 0) {
              throw new Error();
            } else {
              return result.Result;
            }
          }),
          retry(10),
          catchError(() => {
            return of({});
          })
        );
    } else {
      return forkJoin({
        appLabels: this._http
          .get<any>(
            oneLine`${environment.apiUrl}/responsive/web/app/portalclient/labels`
          )
          .pipe(
            map(result => {
              if (Object.keys(result.Result).length === 0) {
                throw new Error();
              } else {
                return result.Result;
              }
            }),
            retry(10),
            catchError(() => {
              return of({});
            })
          ),
        gamepartLabels: this._http
          .get<any>(
            oneLine`${
              environment.apiUrl
            }/json/ActualOfferService.svc/GameParts/LanguageID/${lang.toLowerCase()}`
          )
          .pipe(
            map((labels): any =>
              Object.values(labels).reduce(
                (a: any, c: any) => ({
                  ...a,
                  ['app_gamepart_' + c.ID]: c.Name
                }),
                {}
              )
            )
          )
      }).pipe(
        map(({ appLabels }) => ({
          ...appLabels
        }))
      );
    }
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SeoModule,
    StoreModule.forRoot(fromApp.appReducer, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false
      }
    }),
    IonicModule.forRoot({
      mode: 'md'
    }),
    EffectsModule.forRoot([AuthEffects, MaintenanceEffects]),
    NotificationsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: CustomTranslateLoader,
        deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(
      [
        {
          path: '',
          loadChildren: () =>
            import('../app-routing.module').then(m => m.AppRoutingModule),
          canLoad: [IsRoutingInitializedGuard]
        }
      ],
      {
        onSameUrlNavigation: 'reload'
      }
    ),
    CaptchaModule
  ],
  providers: [
    RouteConfigLoader,
    CookieService,
    TimezoneService,
    MaintenanceLoader,
    EmbededOfferService,
    ConfigLoader,
    IsRoutingInitializedGuard,
    {
      provide: IS_APPLICATION_INITIALIZED,
      useValue: new BehaviorSubject(false)
    },
    {
      provide: APP_INITIALIZER,
      useFactory: bootsrap,
      multi: true,
      deps: [Injector]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoginCheckInterceptor,
      multi: true,
      deps: [Store]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JavaBackendInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: class extends IonicRouteStrategy {
        /**
         *  reuse route strategy for offer page
         */
        shouldReuseRoute(
          future: ActivatedRouteSnapshot,
          curr: ActivatedRouteSnapshot
        ): boolean {
          // if (curr.data.isOfferPage && future.data.isOfferPage) {
          //   return curr.data.section === future.data.section;
          // }
          const reusablePages = ['BetslipHistoryDetail'];
          if (
            reusablePages.includes(curr.data.PageName) &&
            curr.data.PageName === future.data.PageName
          ) {
            return true;
          }
          return super.shouldReuseRoute(future, curr);
        }
      }
    },
    {
      provide: DEVICE_CODE,
      useFactory: (cookieService: CookieService) => ({
        getCode: () => {
          const cookiesDeviceCode = cookieService.get('DeviceCode');
          if (isValidDeviceCode(cookiesDeviceCode)) {
            return cookiesDeviceCode;
          } else {
            const deviceCode = generateDeviceCode();
            cookieService.set('DeviceCode', deviceCode);
            return deviceCode;
          }
        }
      }),
      deps: [CookieService]
    }
  ]
})
export class CoreModule {
  constructor(
    location: Location,
    cookiesService: CookieService,
    embededOfferService: EmbededOfferService, //only for initialization in app bootstrap
    buybackService: BuybackService //only for initialization in app bootstrap
  ) {
    const currentLanguage = environment.language;
    try {
      // priorita jazykov kym sa nenacita config
      environment.language =
        cookiesService.get(environment.cookies.lang.key) ||
        location.path().split('/')[1] ||
        navigator.language.split('-')[0] ||
        currentLanguage;
    } catch (ignored) {
      environment.language = currentLanguage;
    }
  }
}
