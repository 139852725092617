import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  ready = false;
  ready$ = new Subject();

  constructor(
    private _translateService: TranslateService,
    private _cookieService: CookieService,
    private _location: Location,
    private _router: Router
  ) {}

  /**
   * Sets application translation.
   * Sets cookies to resolve lang of application in next load.
   * Updates environment language variable, for loading resources in correct language.
   * @param langId language id eg sk | en, if langId is not valid or avilable(see configuration.base.languages),
   * as a default is set default language for application(see environment.language)
   */
  setTranslation(langId: string, reloadAfterUpdate: boolean = true): void {
    this.ready = false;
    // set translation for ngx-translate lib, this will automaticaly fetch labels for language
    this._translateService.setDefaultLang(langId);
    this._translateService
      .use(langId)
      .pipe(take(1))
      .subscribe(() => {
        this.ready = true;
        this.ready$.complete();
      });

    this._cookieService.set(
      environment.cookies.lang.key,
      langId,
      +environment.cookies.lang.expiration,
      '/'
    );

    // set lang in route
    const currentPath: string[] = this._location.path().split('/');

    //reload to refresh routing links
    if (reloadAfterUpdate) {
      this._router.navigate(currentPath);
    }
  }

  /** */
  changeTranslation(langId: string, reloadAfterUpdate: boolean = true): void {
    environment.language = langId;

    this._cookieService.set(
      environment.cookies.lang.key,
      langId,
      +environment.cookies.lang.expiration,
      '/'
    );

    // set lang in route
    const currentPath: string[] = this._location.path().split('/');

    this._translateService.reloadLang(langId);
    this._translateService.use(langId);

    //reload to refresh routing links
    if (reloadAfterUpdate) {
      window.location.reload();
    }
  }
}
