<ion-row
  *ngIf="
    (+ticket.BoosterBonusValueTeaser && isLoggedIn === false) ||
    (+ticket.BoosterBonusValue && isLoggedIn)
  "
  class="padding-bottom"
  >
  <!-- (click)="presentXXLBonusInfo()" -->
  <ion-col>
    <div class="flex items-center h-14 rounded border border-[#DEDEDE]">
      <span class="flex items-center bold pl-5">
        <span class="text-[#EC1B34] !text-5xl icon-ic_xxl-logo"></span>
        <span class="text-[#313841]">Bonus</span>
      </span>
      <!-- <span
        class="icon icon-ic_info-circle-filled text-lg pl-1 text-[#7e848a]"
      ></span> -->
      <span class="text-[#313841] w-full text-end pr-5 text-2xl font-thin">
        <ng-container *ngIf="isLoggedIn === false">{{
          ticket.BoosterBonusValueTeaser | formatNumberWithCurrency : { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces}
        }}</ng-container>
        <ng-container *ngIf="isLoggedIn">{{
          ticket.BoosterBonusValue | formatNumberWithCurrency : { decimalDigits: configuration.base.defaultCurrency.numberDecimalPlaces}
        }}</ng-container>
      </span>
    </div>
  </ion-col>
</ion-row>
