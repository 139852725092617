import { Component, Input } from '@angular/core';
import { Ticket } from '@models/Ticket';
import { TranslateService } from '@ngx-translate/core';
import { TranslateSpecialPipe } from '@shared/pipes/translate.pipe';
import { PresentMessageService } from 'src/app/services/present-message.service';
import { configuration } from '@configuration';

@Component({
  selector: 'doxx-xxl-bonus',
  templateUrl: './xxl-bonus.component.html',
  styleUrls: []
})
export class XxlBonusComponent {
  @Input() ticket: Ticket;
  @Input() isLoggedIn: boolean;
  constructor(
    private _presentMessageService: PresentMessageService,
    private _translateSpecial: TranslateSpecialPipe,
    private _translateService: TranslateService
  ) {}

  configuration = configuration;

  /**
   * presentXXLBonusInfo
   */
  presentXXLBonusInfo(): void {
    this._presentMessageService.presentCustomMessage(
      this._translateSpecial.transform('xxlbonus_text_2'),
      'info-alert',
      this._translateService.instant('xxlbonus_header'),
      [this._translateService.instant('btn_close')]
    );
  }
}
